import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styles from './PostPreview.module.scss'

const PostPreview = ({
  title,
  url,
  excerpt,
}) => (
  <div>
    <h3 className={styles.title}>
      <Link to={url}>{title}</Link>
    </h3>
    <p className={styles.excerpt}>{excerpt}</p>
    <Link to={url}>Read more</Link>
  </div>
)

PostPreview.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  excerpt: PropTypes.string.isRequired
}

export default PostPreview
import React from 'react'
import styles from './index.module.scss'

// Components
import Layout from '../../components/Layout'
import Section from '../../components/Layout/Section'
import PageTitle from '../../components/Elements/PageTitle'
import PostList from '../../components/Blog/PostList'

const BlogIndex = () => (
  <Layout className={styles.blogIndex}>
    <PageTitle title="Updates">
      Product news and updates
    </PageTitle>
    <Section>
      <PostList />
    </Section>
  </Layout>
)

export default BlogIndex
import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import styles from './PostList.module.scss'

// Components
import PostPreview from './PostPreview'

const PostList = ({ data }) => {
  const { edges: posts } = data.allMarkdownRemark
  return (
    <ul className={styles.postList}>
      {posts.map(({ node }, i) =>
        <li className={styles.postItem} key={i}>
          <PostPreview
            title={node.frontmatter.title}
            url={node.fields.slug}
            excerpt={node.excerpt}
          />
        </li>
      )}
    </ul>
  )
}

export default () => (
  <StaticQuery
    query={graphql`
      query PostListQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-page" } }}
        ) {
          edges {
            node {
              id
              excerpt
              frontmatter {
                title
              }
              fields {
                slug
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <PostList data={data} />
    )}
  />
)